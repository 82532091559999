import { faCalendar, faCreditCard, faLocationDot, faPeopleLine } from '@fortawesome/free-solid-svg-icons';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import BulletPoint from '../../components/about/bullet';
import Layout from '../../components/layout';
import PageSection from '../../components/page-section';
import Seo from '../../components/seo';
import ClientOnly from '../../components/client-only';
import ToursList from '../../components/tour-calendar-list/list/ToursList';
import { useDispatch } from "react-redux"
import { Dispatch } from "../../store/store"
import { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
const VistulaDayTicketsAboutPage = () => {
    const goToEventsSchedule = () => { navigate('/swieto-wisly-2023/atrakcje') };
    const { t } = useTranslation();
    const dispatch = useDispatch<Dispatch>();
    useEffect(() => {
        dispatch.toursCalendar.initialize({ translate: t, filterByProject: "SW_23", });
    }, [])
    return (
        <Layout>
            <Seo title={"Święto Wisły 2023 - Bilety"} />
            <PageSection>
                <h1><strong>Bilety online</strong><br />Święto Wisły 2023</h1>
                <div className="py-3"></div>
                <h3>Rezerwacja bezpłatnych biletów na atrakcje obchodów</h3>
                <div className="py-3"></div>

                <p>W atrakcjach biletowanych łącznie może wziąć udział ponad <strong>320 osób</strong>. Prawie 260 biletów udostępniliśmy do rezerwacji online na tej stronie. Pozostała ilość będzie zależna od frekwencji i dostępna na miejscu.</p>
                <div className="pt-3">
                    <a id="goto-tickets-section" href="#toursCalendarSection" role="button" type="button" className="btn btn-secondary btn-lg mb-3 mb-sm-none">Rezerwuj</a>
                    <span className="ps-3"></span>
                    <a id="read-more-tickets" href="#more-about-tickets-section" role="button" className="btn btn-outline-light btn-lg mb-3 mb-sm-none">Czytaj więcej</a>
                </div>
            </PageSection>
            <PageSection className='bg-white text-black' id="more-about-tickets-section" data-bs-spy="scroll" data-bs-target="#read-more-tickets">
                <div className="container-fluid px-4 px-md-0 py-2">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-sm-6">
                                    <BulletPoint icon={faLocationDot} title={"Realizacja rezerwacji"} >
                                        <span>Po mailowym otrzymaniu potwierdzenia rezerwacji należy przyjść w wyznaczone miejsce <strong>najpóźniej 10 min przed terminem rozpoczęcia atrakcji</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCalendar} title={"Otwarcie rezerwacji"} >
                                        <span>Otwarcie zapisów nastąpi w 2 etapach. Pierwszy etap rusza 19.08. O kolejnej puli rezerwacji informować będziemy na <a target="_blank" href="https://www.facebook.com/swietowisly/">profilu społecznościowym Święta Wisły</a>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faPeopleLine} title={"Ograniczenia"} >
                                        <span>Jedna osoba może złożyć rezerwację na grupę <strong>maks. 4 osób na 1 atrakcję</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCreditCard} title={"Kaucja zwrotna"} >
                                        <span>Na każdy z rejsów pobieramy kaucję zwrotną w wysokości <br /><strong>20zł /os</strong>.</span>
                                    </BulletPoint>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageSection>
            <PageSection>
                <h1 className="pb-5">Atrakcje biletowane</h1>
                <div className="py-2" ></div>
                <p>Poniżej znajdują się atrakcje na które można zarezerwować miejsca.</p>
                <p>Po udanej rezerwacji w dniu wydarzenia należy stawić się wcześniej (<strong>najpóźniej na 10 min przed</strong>), w miejscu rozpoczęcia atrakcji.</p>
            </PageSection>
            <PageSection id="toursCalendarSection" className="bg-white text-black position-relative" style={{ minHeight: 400 }}>
                <ClientOnly>
                    <ToursList />
                </ClientOnly>
            </PageSection>
        </Layout>
    );
}

export default VistulaDayTicketsAboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;